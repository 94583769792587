<template>
  <div class="flex flex-col">
    <div class="pb-[23px] pt-[38px]">
      <HeadlineDynamic
        :headline="t('registration.billingAddress.headline')"
        level="h2"
        :has-decoration="true"
      />
    </div>
    <AddressSuggestions
      class="mb-sm !py-sm !px-sm"
      :formkit-address-fields="{
        street: 'registrationStreet',
        number: 'registrationHouseNumber',
        zipcode: 'registrationZip',
        city: 'registrationCity',
        name1: 'registrationOfficeName1',
      }"
    />
    <TextInput
      id="registrationOfficeName1"
      :key="label"
      name="name1"
      :label="`${t(label)} 1`"
      :placeholder="`${t(label)} 1`"
      :required-message="error"
      :maxlength="40"
    />
    <TextInput
      id="registrationOfficeName2"
      v-model="name2"
      name="name2"
      :label="`${t(label)} 2`"
      :placeholder="`${t(label)} 2`"
      :required="false"
      :maxlength="40"
    />
    <Address
      v-model:country="selectedCountry"
      :country-options="countryOptions"
      validate-packing-station
    />
    <Dropdown
      id="registrationOfficeCountry"
      v-model="selectedCountry"
      name="country"
      label="registration.billingAddress.country"
      :options="countryOptions"
      :required="true"
      :validation-message="t('registration.emptyError.billingAddress.country')"
      :custom-select="CustomSelectCountry"
      :disabled="countryOptions.length <= 1"
    />
    <PhoneCanonical :country-code="selectedCountry" :required="false" />
    <Email
      id="registrationOfficeBillingEmail"
      name="billingEmail"
      label="registration.loginData.billingEmail"
      placeholder="registration.loginData.placeholder.email"
    />
  </div>
</template>
<script setup lang="ts">
import HeadlineDynamic from '~~/src/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';
import type { Result } from '~~/src/server/api/[site]/user/register.get';
import Address from '@/components/formFields/addressRows/addressRows.vue';
import AddressSuggestions from '@/components/formTools/address/suggestions/addressSuggestions.vue';
import { Dropdown, TextInput, PhoneCanonical } from '@/complib';
import CustomSelectCountry from '@/components/formFields/components/customSelects/customSelectCountry.vue';
import Email from '@/components/formFields/email/email.vue';

const props = defineProps({
  registerData: {
    type: Object as PropType<Result>,
    required: true,
    default: {} as Result,
  },
  selectedType: {
    type: String,
    required: true,
  },
});

const countryOptions = props.registerData.countries.map((country) => ({
  label: country.name,
  value: country.code,
}));

const selectedCountry = ref(countryOptions[0].value);

function getSelectedGroup() {
  return props.registerData.groups.find(
    (group) => group.id === props.selectedType,
  );
}

const name2changed = ref(false);
const name2 = ref(getSelectedGroup().name);
watch(name2, (newValue) => {
  if (getSelectedGroup().name !== newValue) name2changed.value = true;
});
watch(
  () => props.selectedType,
  () => {
    if (!name2changed.value) {
      name2.value = getSelectedGroup()?.name;
      name2changed.value = false;
    }
  },
);

const label = computed(() => {
  if (props.selectedType) {
    return getSelectedGroup()?.label;
  }
  return 'registration.billingAddress.name';
});

const error = computed(() => {
  if (props.selectedType) {
    const errorName = props.registerData.groups.find(
      (group) => group.id === props.selectedType,
    )?.errorName;
    if (errorName) {
      return t('registration.emptyError.billingAddress.name1', {
        name: errorName,
      });
    }
  }
  return t('registration.emptyError.billingAddress.name1', {
    name: t(label.value),
  });
});

const { t } = useTrans();
</script>
