<template>
  <div class="flex flex-col">
    <div class="pb-[23px] pt-[38px]">
      <HeadlineDynamic
        :headline="t('registration.loginData.headline')"
        level="h2"
        :has-decoration="true"
      />
    </div>
    <TextInput
      id="registrationOfficeTitle"
      name="title"
      label="registration.loginData.title"
      placeholder="registration.loginData.placeholder.title"
      required-message="registration.emptyError.loginData.title"
      :required="false"
    />
    <TextInput
      id="registrationOfficefirstname"
      name="firstname"
      label="registration.loginData.firstname"
      placeholder="registration.loginData.placeholder.firstname"
      required-message="registration.emptyError.loginData.firstname"
    />
    <TextInput
      id="registrationOfficeLastname"
      name="lastname"
      label="registration.loginData.lastname"
      placeholder="registration.loginData.placeholder.lastname"
      required-message="registration.emptyError.loginData.lastname"
    />
    <Email
      id="registrationOfficeEmail"
      name="email"
      label="registration.loginData.email"
      placeholder="registration.loginData.placeholder.email"
    />
    <PasswordValidation />
  </div>
</template>
<script setup lang="ts">
import HeadlineDynamic from '~~/src/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';
import Email from '@/components/formFields/email/email.vue';
import { TextInput } from '@/complib';
import PasswordValidation from '@/components/formFields/passwordValidation/vdv/passwordValidation.vue';

const { t } = useTrans();
</script>
